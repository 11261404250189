@use 'colors';
@use 'theme' as *;

div.speakup-article {
  div.time-progress {
    margin-bottom: 30px;

    span.current-time {
      color: map-get($theme, 'time', 'value');
      font-size: 12px;
      display: block;
      float: left;
      margin-left: 20px;
      font-weight: 300;
    }

    div#progress-container {
      margin-left: 20px;
      margin-right: 20px;
      position: relative;
      height: 20px;
      cursor: pointer;

      /*
            IE 11
          */

      &:hover input[type='range'].amplitude-song-slider {
        &::-webkit-slider-thumb {
          display: block;
        }

        &::-moz-range-thumb {
          visibility: visible;
        }
      }

      progress {
        &#song-played-progress {
          width: 100%;
          position: absolute;
          left: 0;
          top: 8px;
          right: 0;
          width: 100%;
          z-index: 60;
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          height: 4px;
          border-radius: 5px;
          background: transparent;
          border: none;

          /* Needed for Firefox */

          &[value] {
            &::-webkit-progress-bar {
              background: none;
              border-radius: 5px;
            }

            &::-webkit-progress-value {
              background-color: map-get($theme, 'progress-bar', 'value');
              border-radius: 5px;
            }
          }

          &::-moz-progress-bar {
            background: none;
            border-radius: 5px;
            background-color: map-get($theme, 'progress-bar', 'value');
            height: 5px;
            margin-top: -2px;
          }
        }

        &#song-buffered-progress {
          position: absolute;
          left: 0;
          top: 8px;
          right: 0;
          width: 100%;
          z-index: 10;
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          height: 4px;
          border-radius: 5px;
          background: transparent;
          border: none;
          background-color: map-get($theme, 'progress-bar', 'buffered', 'bg');

          &[value] {
            &::-webkit-progress-bar {
              background-color: map-get(
                $theme,
                'progress-bar',
                'buffered',
                'value'
              );
              border-radius: 5px;
            }

            &::-webkit-progress-value {
              background-color: map-get(
                $theme,
                'progress-bar',
                'buffered',
                'value'
              );
              border-radius: 5px;
              transition: width 0.1s ease;
            }
          }

          &::-moz-progress-bar {
            background: none;
            border-radius: 5px;
            background-color: map-get(
              $theme,
              'progress-bar',
              'buffered',
              'value'
            );
            height: 5px;
            margin-top: -2px;
          }
        }

        &::-ms-fill {
          border: none;
        }
      }

      input[type='range'] {
        -webkit-appearance: none;
        width: 100%;
        margin: 7.5px 0;
        position: absolute;
        z-index: 9999;
        top: -7px;
        left: 0;
        height: 20px;
        cursor: pointer;
        background-color: inherit;
        border: 0;
        padding: 0;
        min-height: auto;

        &:focus {
          outline: none;
        }

        &::-webkit-slider-runnable-track {
          width: 100%;
          height: 0px;
          cursor: pointer;
          box-shadow: 0px 0px 0px rgba(0, 0, 0, 0),
            0px 0px 0px rgba(13, 13, 13, 0);
          background: map-get($theme, 'progress-bar', 'value');
          border-radius: 0px;
          border: 0px solid #010101;
        }

        &::-webkit-slider-thumb {
          box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
          border: 1px solid map-get($theme, 'progress-bar', 'value');
          height: 15px;
          width: 15px;
          border-radius: 16px;
          background: map-get($theme, 'progress-bar', 'value');
          cursor: pointer;
          -webkit-appearance: none;
          margin-top: -7.5px;
        }

        &:focus::-webkit-slider-runnable-track {
          background: map-get($theme, 'progress-bar', 'value');
        }

        &::-moz-range-track {
          width: 100%;
          height: 0px;
          cursor: pointer;
          box-shadow: 0px 0px 0px rgba(0, 0, 0, 0),
            0px 0px 0px rgba(13, 13, 13, 0);
          background: map-get($theme, 'progress-bar', 'value');
          border-radius: 0px;
          border: 0px solid #010101;
        }

        &::-moz-range-thumb {
          box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
          border: 1px solid map-get($theme, 'progress-bar', 'value');
          height: 15px;
          width: 15px;
          border-radius: 16px;
          background: map-get($theme, 'progress-bar', 'value');
          cursor: pointer;
        }

        &::-ms-track {
          width: 100%;
          height: 0px;
          cursor: pointer;
          background: transparent;
          border-color: transparent;
          color: transparent;
        }

        &::-ms-fill-lower {
          background: #003d57;
          border: 0px solid #010101;
          border-radius: 0px;
          box-shadow: 0px 0px 0px rgba(0, 0, 0, 0),
            0px 0px 0px rgba(13, 13, 13, 0);
        }

        &::-ms-fill-upper {
          background: map-get($theme, 'progress-bar', 'value');
          border: 0px solid #010101;
          border-radius: 0px;
          box-shadow: 0px 0px 0px rgba(0, 0, 0, 0),
            0px 0px 0px rgba(13, 13, 13, 0);
        }

        &::-ms-thumb {
          box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
          border: 1px solid map-get($theme, 'progress-bar', 'value');
          height: 15px;
          width: 15px;
          border-radius: 16px;
          background: map-get($theme, 'progress-bar', 'value');
          cursor: pointer;
          height: 0px;
          display: none;
        }

        &:focus {
          &::-ms-fill-lower,
          &::-ms-fill-upper {
            background: map-get($theme, 'progress-bar', 'value');
          }
        }
      }
    }

    span.duration {
      color: map-get($theme, 'time', 'value');
      font-size: 12px;
      font-weight: 300;
      display: block;
      float: right;
      margin-right: 20px;
    }
  }

  @media all and (-ms-high-contrast: none) {
    div.time-progress div#progress-container {
      *::-ms-backdrop,
      progress#song-played-progress {
        color: map-get($theme, 'progress-bar', 'value');
        border: none;
        background-color: map-get($theme, 'progress-bar', 'buffered', 'value');
      }
    }
  }

  @supports (-ms-ime-align: auto) {
    div.time-progress div#progress-container progress#song-played-progress {
      color: map-get($theme, 'progress-bar', 'value');
      border: none;
    }
  }

  @-moz-document url-prefix() {
    div.time-progress div#progress-container progress#song-buffered-progress {
      top: 9px;
      border: none;
    }
  }

  @media all and (-ms-high-contrast: none) {
    div.time-progress div#progress-container {
      *::-ms-backdrop,
      progress#song-buffered-progress {
        color: #78909c;
        border: none;
      }
    }
  }

  @supports (-ms-ime-align: auto) {
    div.time-progress div#progress-container progress#song-buffered-progress {
      color: #78909c;
      border: none;
    }
  }

  @media all and (-ms-high-contrast: none) {
    div.time-progress div#progress-container {
      *::-ms-backdrop,
      input[type='range'].amplitude-song-slider {
        padding: 0px;
      }

      *::-ms-backdrop,
      input[type='range'].amplitude-song-slider::-ms-thumb {
        height: 15px;
        width: 15px;
        border-radius: 10px;
        cursor: pointer;
        margin-top: -8px;
      }

      *::-ms-backdrop,
      input[type='range'].amplitude-song-slider::-ms-track {
        border-width: 15px 0;
        border-color: transparent;
      }

      *::-ms-backdrop,
      input[type='range'].amplitude-song-slider::-ms-fill-lower,
      *::-ms-backdrop,
      input[type='range'].amplitude-song-slider::-ms-fill-upper {
        background: map-get($theme, 'progress-bar', 'value');
        border-radius: 10px;
      }
    }
  }

  @supports (-ms-ime-align: auto) {
    div.time-progress
      div#progress-container
      input[type='range'].amplitude-song-slider::-ms-thumb {
      height: 15px;
      width: 15px;
      margin-top: 3px;
    }
  }
}
