@use 'colors';
@use 'theme' as *;

div.speakup-article {
  max-height: 375px;
  margin: 0 auto;
  padding: 5px;

  div.center {
    div#speakup-song-image {
      text-align: center;

      img {
        border-radius: 8px;
        box-shadow: map-get($theme, 'shadow');
      }
    }

    img.main-album-art {
      display: block;
      margin: auto;
      margin-bottom: 16px;
      border-radius: 8px;
      box-shadow: map-get($theme, 'shadow');
      max-width: 200px;
      max-height: 130px;

      &.adv {
        opacity: 0.15;
      }
    }

    div {
      &.song-meta-data {
        div.song-name-container {
          position: relative;
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          align-items: center;

          span.song-name {
            flex: 1 1 160px;
            min-width: 160px;
            min-height: 40px;
            max-width: -webkit-fit-content;
            max-width: -moz-fit-content;
            max-width: fit-content;
            font-weight: 500;
            color: map-get($theme, 'colors', 'primary');
            display: block;
            text-align: center;
            font-size: 15px;
            margin: 5px 10px;
            padding: 2px 45px;
            line-height: 1.25;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
          }
          a.song-link {
            position: absolute;
            display: inline-block;
            right: 20px;
            height: 32px;
            width: 32px;
            cursor: pointer;
            vertical-align: middle;
            background: map-get($theme, 'buttons', 'link');
            background-repeat: no-repeat;
          }
        }
        span {
          &.song-artist {
            color: map-get($theme, 'colors', 'secondary');
            min-height: 20px;
            display: block;
            text-align: center;
            font-size: 13px;
            font-weight: 300;

            &.promoted {
              font-weight: 500;
            }
          }
        }

        div.song-promoted {
          position: absolute;
          top: 25px;
          left: 25px;
          height: 18px;
          width: 18px;
          vertical-align: middle;
          background: map-get($theme, 'buttons', 'playlist', 'promoted');
          background-repeat: no-repeat;
        }
      }
    }
  }
}
