.yearlyPicker {
  position: relative;
  display: inline-block;
  border-radius: 2px;
  border: 1px solid #dbdbdb;

  .yearlyPicker_calendarIcon {
    background: 0 0;
    border: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;
    padding: 10px;
    margin: 0 5px 0 10px;

    svg {
      fill: #82888a;
      height: 15px;
      width: 14px;
      vertical-align: middle;
      overflow: hidden;
    }
  }

  .yearlyPicker_input {
    margin: 0;
    padding: 0;
    background: #fff;
    position: relative;
    display: inline-block;
    width: 70px;
    vertical-align: middle;
    cursor: pointer;

    .yearlyPicker_input_value {
      color: #484848;
      font-weight: 200;
      font-size: 15px;
      line-height: 18px;
      letter-spacing: 0.2px;
      padding: 7px 15px 5px;
    }
  }

  select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: none;
    border: none;
    outline: none;
    box-shadow: none;
    padding: 0;
    margin: 0;
    font-size: 1em;
    color: inherit;
    cursor: pointer;
  }
}
