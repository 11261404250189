@use 'colors';
@use 'theme' as *;

div.speakup-article {
  div#player-controls {
    width: 100%;
    display: flex;
    padding: 15px 0;
    align-items: center;
    justify-content: space-evenly;

    div {
      &#shuffle {
        display: inline-block;
        width: 19px;
        height: 16px;
        cursor: pointer;
        vertical-align: middle;

        &.amplitude-shuffle-off {
          background: map-get($theme, 'buttons', 'playlist', 'shuffle-off');
        }

        &.amplitude-shuffle-on {
          background: map-get($theme, 'buttons', 'playlist', 'shuffle-on');
        }
      }

      &#previous {
        display: inline-block;
        height: 44px;
        width: 44px;
        cursor: not-allowed;
        background: map-get($theme, 'buttons', 'prev');
        vertical-align: middle;
      }

      &.skip-seconds {
        display: inline-block;
        height: 32px;
        width: 32px;
        cursor: not-allowed;
        vertical-align: middle;
      }

      &#prev-10 {
        background: map-get($theme, 'buttons', 'playlist', 'prev-10');
      }

      &#next-10 {
        background: map-get($theme, 'buttons', 'playlist', 'next-10');
      }

      &#play-pause {
        display: inline-block;
        width: 70px;
        height: 70px;
        cursor: pointer;
        vertical-align: middle;

        &.amplitude-paused {
          background: map-get($theme, 'buttons', 'play', 'bg');
          background-size: contain;
          background-repeat: no-repeat;
          box-shadow: map-get($theme, 'buttons', 'play', 'shadow');
          border-radius: 50%;
          -webkit-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
          -moz-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
          -ms-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
          animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
        }

        &.amplitude-playing {
          background: map-get($theme, 'buttons', 'pause');
          background-size: contain;
          background-repeat: no-repeat;
        }
      }

      &#next {
        display: inline-block;
        height: 44px;
        width: 44px;
        cursor: not-allowed;
        background: map-get($theme, 'buttons', 'next');
        vertical-align: middle;
      }

      &#repeat {
        display: inline-block;
        width: 18px;
        height: 16px;
        cursor: pointer;
        vertical-align: middle;

        &.amplitude-repeat-off {
          background: map-get($theme, 'buttons', 'playlist', 'repeat-off');
        }

        &.amplitude-repeat-on {
          background: map-get($theme, 'buttons', 'playlist', 'repeat-on');
        }
      }
    }
  }

  @-webkit-keyframes pulse {
    to {
      box-shadow: map-get($theme, 'buttons', 'play', 'pulse-shadow');
    }
  }

  @-moz-keyframes pulse {
    to {
      box-shadow: map-get($theme, 'buttons', 'play', 'pulse-shadow');
    }
  }

  @-ms-keyframes pulse {
    to {
      box-shadow: map-get($theme, 'buttons', 'play', 'pulse-shadow');
    }
  }

  @keyframes pulse {
    to {
      box-shadow: map-get($theme, 'buttons', 'play', 'pulse-shadow');
    }
  }
}
